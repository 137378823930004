var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('p',{staticClass:"text-muted font-13 mb-0"}),_c('div',[_c('good-table-column-search',{attrs:{"moduleapi":((this.baseApi) + "/receipt"),"columns":_vm.columns,"title":"Receipt","routerpath":"/accounting/addreceipt","formOptions":_vm.formOptions,"edit":_vm.edit,"add":_vm.add,"canDelete":_vm.canDelete,"viewComponent":"receiptShow"},scopedSlots:_vm._u([{key:"customField",fn:function(ref){
var props = ref.props;
return [(props.column.field === 'plots')?_c('span',[_vm._v(" "+_vm._s(_vm.setPlots(props.row))+" ")]):_vm._e()]}},{key:"action",fn:function(ref){
var row = ref.row;
var getGridData = ref.getGridData;
return [_c('div',{staticClass:"d-flex"},[(
                      _vm.approvalAccess &&
                      (row.approved1 == 'Pending' || row.approved1 == 'Rejected')
                    )?_c('b-badge',{staticClass:"mr-50 cursor-pointer",attrs:{"variant":"primary","id":'recieptApprove' + row.id},on:{"click":function($event){return _vm.approveReceipt(row, 'Approved', getGridData)}}},[(row.loading)?_c('b-spinner',{attrs:{"small":"","variant":"light"}}):_c('feather-icon',{attrs:{"icon":"CheckIcon"}})],1):_vm._e(),(
                      _vm.approvalAccess &&
                      (row.approved1 == 'Pending' || row.approved1 == 'Rejected')
                    )?_c('b-tooltip',{attrs:{"target":'recieptApprove' + row.id,"triggers":"hover"}},[_vm._v(" Approve ")]):_vm._e(),(_vm.approvalAccess && row.approved1 != 'Rejected')?_c('b-badge',{staticClass:"mr-50 cursor-pointer",attrs:{"variant":"danger","id":'recieptReject' + row.id},on:{"click":function($event){return _vm.approveReceipt(row, 'Rejected', getGridData)}}},[(row.loading)?_c('b-spinner',{attrs:{"small":"","variant":"light"}}):_c('feather-icon',{attrs:{"icon":"XIcon"}})],1):_vm._e(),(_vm.approvalAccess && row.approved1 != 'Rejected')?_c('b-tooltip',{attrs:{"target":'recieptReject' + row.id,"triggers":"hover"}},[_vm._v(" Reject ")]):_vm._e(),(
                      row.approved1 == 'Approved' && row.transactiontype !== 'Other'
                    )?_c('b-badge',{staticClass:"mr-50 cursor-pointer",attrs:{"id":'download' + row.id,"variant":"success"},on:{"click":function($event){return _vm.handelRecieptPDF(row)}}},[(row.downloading)?_c('b-spinner',{attrs:{"small":"","variant":"light"}}):_c('feather-icon',{attrs:{"icon":"DownloadIcon"}})],1):_vm._e(),(
                      row.approved1 == 'Approved' && row.transactiontype !== 'Other'
                    )?_c('b-tooltip',{attrs:{"id":'download' + row.id,"target":'download' + row.id,"triggers":"hover"}},[_vm._v(" Download ")]):_vm._e()],1)]}}])})],1)])])])]),_c('vue-html2pdf',{ref:"html2Pdf",attrs:{"show-layout":false,"float-layout":true,"enable-download":true,"preview-modal":false,"paginate-elements-by-height":1400,"filename":"Receipt","html2canvas":{},"jsPDF":{
      format: 'a5',
    },"pdf-quality":3,"manual-pagination":false,"pdf-format":"a5","pdf-margin":0,"pdf-orientation":"landscape","pdf-content-width":"1800px"}},[_c('section',{attrs:{"slot":"pdf-content"},slot:"pdf-content"},[_c('print-comp',{attrs:{"receiptData":_vm.receiptData}})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }